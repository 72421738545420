.app-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.layout-container{
  display: flex;
  gap: 20px;
}

.layout-container > :first-child {
  flex: 2;
  overflow: auto;
}

.layout-container > :last-child {
  flex: 1;
  max-height: 600px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.message {
  padding: 8px;
  margin-bottom: 5px;
  border-radius: 5px;
  clear: both;
  word-wrap: break-word; /* Ensure words break and wrap within the bubble */
  overflow-wrap: break-word; /* Break long words */
}

/* Styles for user messages */
.user-message {
  background-color: #f1f1f1;
  text-align: justify;
}

/* Styles for system messages */
.system-message {
  background-color: rgba(0, 0, 0, 0.05);
  text-align: center;
  font-style: italic;
  color: #666;
  margin-left: auto;
  margin-right: auto;
  width: fit-content; /* Fit content for centering */
}

.my-message {
  background-color: #dcf8c6; /* Light green for current user's messages */
  text-align: justify;
  float: right;
  margin-right: 10px; /* Right alignment */
  word-wrap: break-word; /* Ensure text wraps properly */
  overflow-wrap: break-word; /* Break long words */
}

.other-message {
  background-color: rgba(0, 0, 0, 0.05); /* grey for other users' messages */
  text-align: justify;
  float: left;
  margin-left: 10px; /* Left alignment */
  border: 1px solid #ddd;
  word-wrap: break-word; /* Ensure text wraps properly */
  overflow-wrap: break-word; /* Break long words */
}

h1 {
  margin-bottom: 20px;
  color: #333;
  font-family: Arial, sans-serif;
}

table {
  margin: 0 auto;
  width: 80%;
  border-collapse: collapse;
}

th, td { 
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

tf {
  background-color: #f2f2f2;
}

button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #cccccc; /* Duller color for disabled button */
  color: #666666;            /* Text color for disabled button */
  cursor: not-allowed;       /* Change cursor to indicate button is not clickable */
  opacity: 0.6;              /* Add opacity for a dull effect */
}


input[type="text"], input[type="email"] {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.add-user-button {
  margin-bottom: 20px;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.add-user-button:hover {
  background-color: #0056b3;
}